@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.scrollbar-hide::-webkit-scrollbar {
		display: none;
	}
	
	/* For IE, Edge and Firefox */
	.scrollbar-hide {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}	
}

html,
body {
	font-family: 'Inter', sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
	background: white;
	color: black;
	min-width: 320px;
	margin: 0 auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'CustomFont';
	src: url('./font/RG-StandardSemibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: block;
}

.custom-font {
	font-family: 'CustomFont', sans-serif;
}

textarea::placeholder {
	opacity: 0.6;
	@apply transition-opacity duration-500
}

textarea:focus::placeholder {
	opacity: 0
}

strong {
	font-weight: 500
}

.hidden-pointer {
	opacity: 0;
	pointer-events: none;
}

.dark-icon {
	#left-leaf {
		fill: #BCC0FF;
	}
	#center-leaf {
		fill: #9BA0FF;
	}
	#right-leaf {
		fill: #7A80FF;
	}
}

@keyframes leftLeafAnimate {
  0% {
    transform: translateX(0) translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateX(-5px) translateY(1.5px) rotate(-35deg);
  }
	100% {
    transform: translateX(0) translateY(0px) rotate(0deg);
  }
}

@keyframes rightLeafAnimate {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  50% {
    transform: translateX(5px) translateY(1.5px) rotate(35deg);
  }
	100% {
		transform: translateX(0) translateY(0) rotate(0deg);
	}
} 

@media(min-width: 768px) {
	@keyframes leftLeafAnimate {
		0% {
			transform: translateX(0) translateY(0) rotate(0deg);
		}
		50% {
			transform: translateX(-12px) translateY(4px) rotate(-35deg);
		}
		100% {
			transform: translateX(0) translateY(0) rotate(0deg);
		}
	}
	@keyframes rightLeafAnimate {
		0% {
			transform: translateX(0) translateY(0) rotate(0deg);
		}
		50% {
			transform: translateX(12px) translateY(4px) rotate(35deg);
		}
		100% {
			transform: translateX(0) translateY(0) rotate(0deg);
		}
	}
}

.light-icon {
	#left-leaf {
		animation: leftLeafAnimate 4s ease-in-out infinite;
	}
	#right-leaf {
		animation: rightLeafAnimate 4s ease-in-out infinite;
	}
}

.answer-full {
	> div {
		> div {
			> div&:last-child {
				margin-bottom: 148px;
			}
		}
	}
}